:root {
  --c-primary-0: #191489;
  --c-primary-1: #2920DF;
  --c-primary-2: #5080B0;
  --c-primary-3: #090F47;
  --c-danger-0: #FF3B3B;
  --c-danger-1: #E63535;
  --c-danger-2: #FF5C5C;
  --c-danger-3: #FF8080;
  --c-warning-0: #FFCC00;
  --c-warning-1: #E6B800;
  --c-warning-2: #FDDD48;
  --c-warning-3: #FDED72;
  --c-success-0: #06C270;
  --c-success-1: #05A660;
  --c-success-2: #39D98A;
  --c-success-3: #57EBA1;
  --c-info-0: #0063F7;
  --c-info-1: #004FC4;
  --c-info-2: #5B8DEF;
  --c-info-3: #9DBFF9;
  --c-dark-0: #3A3A3C;
  --c-dark-1: #6B7588;
  --c-dark-2: #8F90A6;
  --c-dark-3: #C7C9D9;
  --c-grey-0: #8D9091;
  --c-gray-4: #EAE9FC;
  --c-grey-1: #CCCCCC;
  --c-grey-2: #EFEFEF;
  --cc-grey-3: #FBFBFB;
  --c-bg-color: #F7F7FB;
  --text-bg: #EAE9FC;
  --scroll: #8F90A680;
  --c-white-2: #FFFFFF;
  --c-text: #040316;
  --c-sec: #ededf5;
}

@font-face {
  font-family: SofiaPro;
  src: url('./assets/fonts/Sofia\ Pro\ Regular\ Az.otf');
}

@font-face {
  font-family: SofiaProBold;
  src: url('./assets/fonts/Sofia\ Pro\ Bold\ Az.otf');
}

@font-face {
  font-family: Lota;
  src: url('./assets/fonts/Los\ Andes\ \ Lota\ Grotesque\ Regular.otf');
}

body {
  background: var(--c-bg-color);
  font-family: SofiaPro !important;
}

thead {
  background: var(--text-bg);
  color: black;
}

*::-webkit-scrollbar,
body::-webkit-scrollbar,
.App::-webkit-scrollbar,
.scroll::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

body::-webkit-scrollbar-thumb,
.App::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background: linear-gradient(to top, #333, var(--scroll));
  border-radius: 10px;
  font-family: sofiaPro !important;
}

body::-webkit-scrollbar-track,
.App::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track {
  width: 8px;
}

.---plan::-webkit-scrollbar,
.---plan::-webkit-scrollbar-track {
  height: 5px !important;
  width: 2px !important;
}

.carousel .control-dots {
  text-align: start !important;
}

.carousel .control-dots .dot {
  background: var(--c-primary-0) !important;
}

@media screen and (max-width: 425px) {
  .carousel .control-dots {
    text-align: center !important;
  }
}

a,
span {
  font-family: sofiaPro !important
}

.date-picker-wrapper .react-datepicker__input-container input::placeholder {
  color: var(--c-primary-0);
}



.poppins-light {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300 !important;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  /* font-style: normal; */
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif !important;
  font-weight: 800 !important;
  font-style: normal;
}